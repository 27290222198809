import React from 'react'

const Blogs = () => {
    return (
        <>
            <div className='Service_Banner'>
                <div className='container'>
                    <h1>Blogs</h1>
                </div>
            </div>
            <div className='container mt-5 mb-5' >
               <div className='row'>
                <div className='col-md-4 col-sm-12 text-center'>
                    <div className='card'>
                    <img src='Stronger Bones.jpg' alt='Top Foods for Stronger Bones(Srinivas polisetty)'></img>
                    <p><b>Top Foods for Stronger Bones: Boost Your Bone Health Naturally</b></p>
                        <a className="mb-2" href='Top_Foods_for_Stronger_Bones_Boost_Your_Bone_Health_Naturally'>click here</a>
                    </div>
                </div>

                
                <div className='col-md-4 col-sm-12 text-center'>
                    <div className='card'>
                    <img src='blog_Fast_track_joint.png' alt='FastTrack Joint Replacement(Srinivas polisetty)' height={240}></img>
                    <p><b>FastTrack Joint Replacement: Transforming Recovery with Speed and Precision</b></p>
                        <a className="mb-2" href='FastTrack_Joint_Replacement_Transforming_Recovery_with_Speed_and_Precision'>click here</a>
                    </div>
                </div>
               </div>
            </div>

            
        </>
    )
}

export default Blogs